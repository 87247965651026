em{
  font-style: normal;
}
form br,
form p:empty{
  display: none;
}

button,
textarea,
input[type="email"],
input[type="text"],
input[type="submit"]{
  appearance: none;
  border: none;
  border-radius: 0;
}

.mw_wp_form .error,
.require{
  font-size: rem-calc(11);
  color: $red;
}
.require{
  vertical-align: super;
}
.mw_wp_form .error{
  position: absolute;
  right: 0; bottom: rem-calc(2);
}

form{
  margin: rem-calc(16) auto;
}
form button,
input[type="submit"]{
  appearance: none;
  padding: rem-calc(8) rem-calc(48) rem-calc(8) rem-calc(16);
  background-color: $black;
  color: $white;
}
.form-back-button{
  background-color: $gray;
}

.form-input--submit,
.form-input{
  position: relative;
  margin-bottom: rem-calc(16);
}
.form-input{
  .require{
    display: block;
  }
  label{
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    left: rem-calc(2);
    bottom: rem-calc(8);
    max-width: rem-calc(90);
    font-size: rem-calc(12);
  }
  .mw_wp_form_confirm & label{
    position: static;
  }
  input[type="text"],
  input[type="email"]{
    width: 100%;
    padding: rem-calc(11);
    padding-left: rem-calc(66);
    border: none;
    border-bottom: 1px solid;
    font-size: rem-calc(18);
  }
}
.form-input--checkbox,
.form-input--textarea{
  position: relative;
  display: flex;
  @include breakpoint(small down){
    flex-direction: column;
    & > label{ margin-bottom: rem-calc(8); }
  };
  & > label{
    min-width: 9em;
  }
  &__inner{
    flex-grow: 1;
  }
}
.form-input--checkbox{
  margin-bottom: rem-calc(16);
  em{
    display: block;
    margin: rem-calc(4) 0 0;
  }
  .tydo-column__row div{
    margin-right: rem-calc(32);
  }
}
.form-input--textarea{
  margin-top: rem-calc(32);
  margin-bottom: rem-calc(32);
  textarea{
    width: 100%;
    border: none;
    border-left: 1px solid;
  }
}
.form-input--submit{
  flex-direction: row-reverse;
  justify-content: flex-end;
  @include breakpoint(small down){
    flex-direction: column-reverse;
  };
  & > div{ margin-bottom: rem-calc(16); }
  & > div + div{
    margin-right: rem-calc(32);
  }
}
