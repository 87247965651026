$gray: #a6a8a7;
$black: #000;
$white: #fff;
$red: #dc2131;

a{
  transition: color 0.25s ease(out-expo);
  color: $black;
  &:hover{
    color: $gray;
  }
}

*::selection {
  background: $gray;
  color: $white;
}
