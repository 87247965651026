.fixed-ui{
  position: fixed;
  z-index: 256;
  transition: color 0.5s linear;
  body.scrolling &,
  body.slider-shown &{
    color: $white;
  }
}

.scroller-container{
  bottom: 0;
  right: 0;
  max-height: calc(100vh - #{rem-calc(424)});
  height: calc(100vh / 3);
  margin: rem-calc(16) rem-calc(28);
  &:after{
    position: absolute;
    content: "";
    display: block;
    top: 0;
    bottom: 0;;
    border-left: 1px solid;
  }
}

.scroller{
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  height: 30px;
  border-left: 3px solid;
  transition: color 0.5s linear;
  body.scrolling &,
  body.slider-shown &{
    color: $white;
  }
  // &:after{
  //   content: "scroll";
  //   font-size: rem-calc(11);
  // }
}
