@import "node_modules/tiny-slider/src/tiny-slider.scss";

.tns-outer,
.tns-slider{
  display: flex;
  align-items: center;
  position: fixed; z-index: -1;
  top: 0; bottom: 0;
  left: 0; right: 0;
  width: 100vw; height: 100vh;
}

#bg-slider > .tns-item{
  @extend .image-contain;
  align-self: center;
  width: 100vw;
  height: 100vh;
}
