body{
  // @extend %column-grid;
  @extend %rem-grid;
}
html{
}

$columnsize: 6;

$gridline-gray: transparent;
$gridline-cyan: transparent;
$gridline-magenta: transparent;
// $gridline-gray: hsla(0,0%,47.1%,.1);
// $gridline-cyan: rgba(20,230,245,.3);
// $gridline-magenta: rgba(230,20,128,.3);

%rem-grid{
  $baseline:  rem-calc(32);
  background-image: repeating-linear-gradient( 0deg,
    $gridline-gray,
    $gridline-gray 1px,

    transparent 0,
    transparent calc(#{$baseline} / 2),

    $gridline-cyan calc(#{$baseline} / 2),
    $gridline-cyan calc(#{$baseline} / 2 + 1px),

    transparent calc(#{$baseline} / 2 + 1px),
    transparent #{$baseline}
  );
	background-repeat: repeat-y;
	background-size: 100% #{$baseline};
	background-position: 0 .5px;
};

%column-grid{
  $basecolumn:  #{(100% / $columnsize)};
  $gutter: rem-calc(16 / 2);
  background-image: repeating-linear-gradient( 90deg,
    $gridline-gray,
    $gridline-gray 1px,

    transparent 0,
    transparent calc(#{$gutter}),

    $gridline-magenta calc(#{$gutter}),
    $gridline-magenta calc(#{$gutter} + 1px),

    transparent calc(#{$gutter} + 1px),
    transparent calc( 100% - #{$gutter}),

    $gridline-magenta calc(100% - #{$gutter}),
    $gridline-magenta calc(100% - #{$gutter} + 1px),

    transparent calc( 100% - #{$gutter} + 1px),
    transparent calc( 100% )

  );
	background-repeat: repeat-x;
	background-size: #{$basecolumn} 100%;
	background-position: -.5px 0;
};
