.screen-reader-text,
.visually-hidden,
.skip-anchor:not(:focus) {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
  padding: 0;
}

.skip-anchor {
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

* {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
}

a, p {
  word-wrap: break-word;
}

main {
  display: block;
}

figure {
  margin: 0;
}

address {
  font-style: normal;
}

img {
  max-width: 100%;
}

.no-bullet, .tydo-social__lists {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.align-middle {
  align-items: center;
}

.image-cover, img {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.image-contain, #bg-slider > .tns-item, img.contain, .contain img {
  object-fit: contain;
  font-family: "object-fit: contain;";
}

img {
  overflow: hidden;
  vertical-align: bottom;
}
@keyframes blink-once {
  0%, 49%, 100% {
    opacity: 1;
  }
  50%, 99% {
    opacity: 0;
  }
}
a {
  transition: color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
  color: #000;
}
a:hover {
  color: #a6a8a7;
}

*::selection {
  background: #a6a8a7;
  color: #fff;
}

body, b, strong {
  font-family: "Futura ND W01 Medium", "AxisStd-Medium", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

b, strong {
  font-family: "Futura ND Demibold W01 Regular", "AxisStd-Bold", sans-serif;
}

.en, p.en, .en b, b.en, .en strong, strong.en {
  font-family: "Futura ND Demibold W01 Regular", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: inherit;
}

.en, p.en, .en b, b.en, .en strong, strong.en {
  font-feature-settings: "tnum", "pwid", "liga" 0, "clig" 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-feature-settings: "pwid";
}

i {
  font-feature-settings: "ital";
}

a {
  text-decoration: none;
}

body {
  background-image: repeating-linear-gradient(0deg, transparent, transparent 1px, transparent 0, transparent calc(2rem / 2), transparent calc(2rem / 2), transparent calc(2rem / 2 + 1px), transparent calc(2rem / 2 + 1px), transparent 2rem);
  background-repeat: repeat-y;
  background-size: 100% 2rem;
  background-position: 0 0.5px;
}

.site-header, .site-content {
  background-image: repeating-linear-gradient(90deg, transparent, transparent 1px, transparent 0, transparent calc(0.5rem), transparent calc(0.5rem), transparent calc(0.5rem + 1px), transparent calc(0.5rem + 1px), transparent calc( 100% - 0.5rem), transparent calc(100% - 0.5rem), transparent calc(100% - 0.5rem + 1px), transparent calc( 100% - 0.5rem + 1px), transparent calc( 100% ));
  background-repeat: repeat-x;
  background-size: 16.6666666667% 100%;
  background-position: -0.5px 0;
}

.fixed-ui {
  position: fixed;
  z-index: 256;
  transition: color 0.5s linear;
}
body.scrolling .fixed-ui, body.slider-shown .fixed-ui {
  color: #fff;
}

.scroller-container {
  bottom: 0;
  right: 0;
  max-height: calc(100vh - 26.5rem);
  height: calc(100vh / 3);
  margin: 1rem 1.75rem;
}
.scroller-container:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  bottom: 0;
  border-left: 1px solid;
}

.scroller {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  height: 30px;
  border-left: 3px solid;
  transition: color 0.5s linear;
}
body.scrolling .scroller, body.slider-shown .scroller {
  color: #fff;
}

.checkbox-sub {
  display: none;
  margin-top: 1rem;
}
.checkbox-sub br, .checkbox-sub.show {
  display: block;
}

.mwform-radio-field.horizontal-item,
.mwform-checkbox-field.horizontal-item {
  display: inline-block;
  margin-left: 0 !important;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

.mwform-radio-field label,
.mwform-checkbox-field label {
  display: inline-flex;
  align-items: center;
}
.mwform-radio-field label input,
.mwform-checkbox-field label input {
  margin-top: 0;
  margin-bottom: 0;
}

em {
  font-style: normal;
}

form br,
form p:empty {
  display: none;
}

button,
textarea,
input[type=email],
input[type=text],
input[type=submit] {
  appearance: none;
  border: none;
  border-radius: 0;
}

.mw_wp_form .error,
.require {
  font-size: 0.6875rem;
  color: #dc2131;
}

.require {
  vertical-align: super;
}

.mw_wp_form .error {
  position: absolute;
  right: 0;
  bottom: 0.125rem;
}

form {
  margin: 1rem auto;
}

form button,
input[type=submit] {
  appearance: none;
  padding: 0.5rem 3rem 0.5rem 1rem;
  background-color: #000;
  color: #fff;
}

.form-back-button {
  background-color: #a6a8a7;
}

.form-input--submit,
.form-input {
  position: relative;
  margin-bottom: 1rem;
}

.form-input .require {
  display: block;
}
.form-input label {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  left: 0.125rem;
  bottom: 0.5rem;
  max-width: 5.625rem;
  font-size: 0.75rem;
}
.mw_wp_form_confirm .form-input label {
  position: static;
}
.form-input input[type=text],
.form-input input[type=email] {
  width: 100%;
  padding: 0.6875rem;
  padding-left: 4.125rem;
  border: none;
  border-bottom: 1px solid;
  font-size: 1.125rem;
}

.form-input--checkbox,
.form-input--textarea {
  position: relative;
  display: flex;
}
@media print, screen and (max-width: 39.99875em) {
  .form-input--checkbox,
.form-input--textarea {
    flex-direction: column;
  }
  .form-input--checkbox > label,
.form-input--textarea > label {
    margin-bottom: 0.5rem;
  }
}
.form-input--checkbox > label,
.form-input--textarea > label {
  min-width: 9em;
}
.form-input--checkbox__inner,
.form-input--textarea__inner {
  flex-grow: 1;
}

.form-input--checkbox {
  margin-bottom: 1rem;
}
.form-input--checkbox em {
  display: block;
  margin: 0.25rem 0 0;
}
.form-input--checkbox .tydo-column__row div {
  margin-right: 2rem;
}

.form-input--textarea {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.form-input--textarea textarea {
  width: 100%;
  border: none;
  border-left: 1px solid;
}

.form-input--submit {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
@media print, screen and (max-width: 39.99875em) {
  .form-input--submit {
    flex-direction: column-reverse;
  }
}
.form-input--submit > div {
  margin-bottom: 1rem;
}
.form-input--submit > div + div {
  margin-right: 2rem;
}

.slider-controls {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  max-width: 100vw;
  height: 100vh;
  color: #fff;
}
.slider-controls__work-title {
  position: absolute;
  top: 1rem;
  left: 1rem;
  margin: 0;
  padding-right: 4rem;
  font-size: 0.6875rem;
  line-height: 1.25;
}
.slider-controls__next {
  order: 3;
}
.slider-controls__next:hover {
  padding-right: 3.5rem;
  padding-left: 4.5rem;
}
@media print, screen and (max-width: 39.99875em) {
  .slider-controls__next:hover {
    padding-right: 1rem;
    padding-left: 2rem;
  }
}
.slider-controls__next:before {
  border-top: 1px solid;
  border-right: 1px solid;
}
.slider-controls__prev {
  order: 1;
}
.slider-controls__prev:hover {
  padding-right: 4.5rem;
  padding-left: 3.5rem;
}
@media print, screen and (max-width: 39.99875em) {
  .slider-controls__prev:hover {
    padding-right: 1rem;
    padding-left: 2rem;
  }
}
.slider-controls__prev:before {
  border-left: 1px solid;
  border-bottom: 1px solid;
}
.slider-controls__next, .slider-controls__prev {
  transition: padding 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  padding: 4rem;
  background-color: transparent;
  color: #fff;
  outline: none;
  cursor: pointer;
}
@media print, screen and (max-width: 39.99875em) {
  .slider-controls__next, .slider-controls__prev {
    padding: 1.5rem;
  }
}
.slider-controls__next:before, .slider-controls__prev:before {
  content: "";
  display: block;
  transform-origin: center;
  transform: rotate(45deg);
  width: 2.75rem;
  height: 2.75rem;
}
@media print, screen and (max-width: 39.99875em) {
  .slider-controls__next:before, .slider-controls__prev:before {
    width: 1.75rem;
    height: 1.75rem;
  }
}
.slider-controls__center {
  order: 2;
  display: flex;
  width: 12.375rem;
  flex-direction: column;
  align-items: center;
}
.slider-controls__title {
  font-size: 0.875rem;
}
.slider-controls__social-link {
  transition: background-color 0.25s linear, color 0.25s linear, width 0.25s cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  align-items: center;
  width: 11rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.375rem;
  background-color: #fff;
}
.slider-controls__social-link[target=_blank] {
  text-decoration: none;
}
.slider-controls__social-link svg {
  margin-right: 0.75rem;
}
.slider-controls__social-link svg path {
  transition: fill 0.5s linear;
}
.slider-controls__social-link:hover {
  width: 12.375rem;
  background-color: #a6a8a7;
  color: #fff;
}
.slider-controls__social-link:hover path {
  fill: #fff;
}

.tydo-social {
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 1rem 0.75rem;
}
.tydo-social__more, .tydo-social__title {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  margin: 0;
  font-size: 0.6875rem;
}
.tydo-social__more {
  transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
  order: 3;
  height: 0;
  margin-bottom: 0;
  border-left: 0 solid;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
body.scrolling .tydo-social__more {
  height: 6rem;
  border-left: 1px solid;
  margin-bottom: 0.25rem;
}
.tydo-social__lists {
  order: 2;
  margin-bottom: 0.75rem;
}
.tydo-social__lists {
  margin-bottom: 0.25rem;
}
.tydo-social__lists a {
  display: block;
  padding: 0.25rem;
  text-align: center;
}
.tydo-social path {
  transition: fill 0.5s linear;
}
.tydo-social path:hover {
  fill: #000;
}
body.slider-shown .tydo-social path, body.scrolling .tydo-social path {
  fill: #fff;
}

html {
  height: 100%;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  html {
    font-size: 1.625vw;
  }
}
@media print, screen and (max-width: 39.99875em) {
  html {
    font-size: 3.75vw;
  }
}

body {
  display: flex;
  flex-direction: column-reverse;
  background-color: #a6a8a7;
  font-size: 0.9375rem;
}

p {
  line-height: 1.75rem;
  letter-spacing: 0.03125rem;
}

.site-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

a[target=_blank] {
  font-size: 0.6875rem;
  text-decoration: underline;
}

.site-content {
  max-width: 80rem;
  width: 100%;
  margin: 0 auto;
}

.page-content {
  margin-left: calc(100% / 6);
  margin-right: calc(100% / 6);
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .page-content {
    margin-left: calc(100% / 12);
    margin-right: calc(100% / 12);
  }
}
@media print, screen and (max-width: 39.99875em) {
  .page-content {
    margin-left: calc(100% / 24);
    margin-right: calc(100% / 9);
  }
}

.tydo-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  padding: 4rem 0;
}
@media print, screen and (max-width: 39.99875em) {
  .tydo-section {
    margin-bottom: 0;
  }
}

.tydo-columns {
  display: flex;
}
@media print, screen and (max-width: 39.99875em) {
  .tydo-columns {
    flex-direction: column;
  }
}

.tydo-column {
  flex-basis: 50%;
  margin: 0;
  margin-bottom: 2rem;
}
.tydo-column, .tydo-column-row {
  padding: 0 0.5rem;
}
.tydo-column-row, .tydo-column__row {
  display: flex;
  align-items: baseline;
  margin-top: -0.125rem;
  margin-bottom: 0.875rem;
}
.tydo-column dt {
  min-width: 4.5em;
  margin-right: 0.5em;
}
.tydo-column dd {
  margin-left: 0;
  margin-right: 1em;
}

div.tydo-column dt {
  margin-top: 0;
  margin-bottom: 0.625rem;
  line-height: 1;
  font-size: 1.25rem;
}
div.tydo-column dd {
  margin-left: 0;
  margin-bottom: -0.25rem;
  font-size: 1.0625rem;
}

@media print, screen and (max-width: 39.99875em) {
  .small-vartical {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
  .small-vartical dt {
    margin-bottom: 0.5rem;
  }
}

.copyright {
  left: 0;
  bottom: 0;
  margin: 1rem;
  line-height: 1;
  font-size: 0.6875rem;
}

.site-header {
  display: flex;
  align-items: center;
  max-width: 80rem;
  width: 100%;
  margin: 0 auto;
  padding: 4rem 0 1.625rem;
}
body.home .site-header {
  height: 100vh;
}

.site-title {
  margin: 0;
  margin-left: calc(100% / 6);
  padding: 0 0.5rem;
  line-height: 1;
  font-size: 1rem;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .site-title {
    margin-left: calc(100% / 12);
  }
}
@media print, screen and (max-width: 39.99875em) {
  .site-title {
    margin-left: calc(100% / 24);
  }
}
.site-title img {
  width: auto;
  height: 1.5rem;
}

body.home .site-container {
  transition: opacity 0.125s linear;
  opacity: 1;
}

.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.tns-outer,
.tns-slider {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
}

#bg-slider > .tns-item {
  align-self: center;
  width: 100vw;
  height: 100vh;
}