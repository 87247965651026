.tydo-social{
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: rem-calc(16) rem-calc(12);
  &__more,
  &__title{
    -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
    margin: 0;
    font-size: rem-calc(11);
  }
  &__more{
    transition: all 0.25s ease(out-expo);
    order: 3;
    height: rem-calc(0);
    margin-bottom: rem-calc(0);
    border-left: 0 solid;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    body.scrolling &{
      height: rem-calc(96);
      border-left: 1px solid;
      margin-bottom: rem-calc(4);
    }
  }
  &__lists{
    @extend .no-bullet;
    order: 2;
    margin-bottom: rem-calc(12);
  }
  &__lists{
    margin-bottom: rem-calc(4);
    a{
      display: block;
      padding: rem-calc(4);
      text-align: center;
    }
  }
  path{
    transition: fill 0.5s linear;
    &:hover{ fill: $black; }
    body.slider-shown &,
    body.scrolling &{ fill: $white; }
  }
}
