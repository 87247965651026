.site-header{
  @extend %column-grid;
  display: flex;
  align-items: center;
  max-width: rem-calc(1280);
  width: 100%;
  margin: 0 auto;
  padding: rem-calc(32 * 2) 0 rem-calc(26);
  body.home &{
    height: 100vh;
  }
}

.site-title{
  margin: 0;
  margin-left: calc(100% / 6);
  padding: 0 rem-calc(8);
  line-height: 1;
  font-size: rem-calc(16);
  @include breakpoint(medium only){
    margin-left: calc(100% / 12);
  };
  @include breakpoint(small down){
    margin-left: calc(100% / 24);
  };
  img{
    width: auto;
    height: rem-calc(24);
  }
}
