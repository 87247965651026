.slider-controls{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  max-width: 100vw;
  height: 100vh;
  color: $white;
  &__work-title{
    position: absolute;
    top: rem-calc(16);
    left: rem-calc(16);
    margin: 0;
    padding-right: rem-calc(64);
    font-size: rem-calc(11);
    line-height: 1.25;
  }
  &__next{
    order: 3;
    &:hover{
      padding-right: rem-calc(64 - 8);
      padding-left: rem-calc(64 + 8);
      @include breakpoint(small down){
        padding-right: rem-calc(24 - 8);
        padding-left: rem-calc(24 + 8);
      };
    }
    &:before{
      border-top: 1px solid;
      border-right: 1px solid;
    }
  }
  &__prev{
    order: 1;
    &:hover{
      padding-right: rem-calc(64 + 8);
      padding-left: rem-calc(64 - 8);
      @include breakpoint(small down){
        padding-right: rem-calc(24 - 8);
        padding-left: rem-calc(24 + 8);
      };
    }
    &:before{
      border-left: 1px solid;
      border-bottom: 1px solid;
    }
  }

  &__next,
  &__prev{
    transition: padding 0.5s ease(out-expo);
    padding: rem-calc(64);
    background-color: transparent;
    color: $white;
    outline: none;
    @include breakpoint(small down){
      padding: rem-calc(24);
    };
    cursor: pointer;
    &:before{
      content: "";
      display: block;
      transform-origin: center;
      transform: rotate(45deg);
      width: rem-calc(44);
      height: rem-calc(44);
      @include breakpoint(small down){
        width: rem-calc(28);
        height: rem-calc(28);
      };
    }
  }
  &__center{
    order: 2;
    display: flex;
    width: rem-calc(198);
    flex-direction: column;
    align-items: center;
  }
  &__title{
    font-size: rem-calc(14);
  }
  &__social-link{
    transition:
      background-color 0.25s linear,
      color 0.25s linear,
      width 0.25s ease(out-expo);
    display: flex;
    align-items: center;
    width: rem-calc(176);
    margin-bottom: rem-calc(8);
    padding: rem-calc(4) rem-calc(6);
    background-color: $white;
    &[target=_blank]{
      text-decoration: none;
    }
    svg{
      margin-right: rem-calc(12);
      path{ transition: fill 0.5s linear; }
    }
    &:hover{
      width: rem-calc(198);
      background-color: $gray;
      color: $white;
      path{ fill: $white; }
    }
  }
}
