.checkbox-sub{
  display: none;
  margin-top: rem-calc(16);
  br,
  &.show{
    display: block;
  }
}

.mwform-radio-field.horizontal-item,
.mwform-checkbox-field.horizontal-item{
  display: inline-block;
  margin-left: 0 !important;
  margin-right: rem-calc(16);
  margin-bottom: rem-calc(8);
  white-space: nowrap
}

.mwform-radio-field label,
.mwform-checkbox-field label{
  display: inline-flex;
  align-items: center;
  input{
    margin-top: 0;
    margin-bottom: 0;
  }
}
