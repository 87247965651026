html{
  height: 100%;
  @include breakpoint(medium only){
    font-size: 1.625vw;
  };
  @include breakpoint(small down){
    font-size: 3.75vw;
  };
}
body{
  display: flex;
  flex-direction: column-reverse;
  background-color: #a6a8a7;
  font-size: rem-calc(15);
}
p{
  line-height: rem-calc(28);
  letter-spacing: rem-calc(0.5);
}
.site-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
}
a[target="_blank"]{
  font-size: rem-calc(11);
  text-decoration: underline;
}

.site-content{
  @extend %column-grid;
  max-width: rem-calc(1280);
  width: 100%;
  margin: 0 auto;
}

.page-content{
  margin-left: calc(100% / 6);
  margin-right: calc(100% / 6);
  @include breakpoint(medium only){
    margin-left: calc(100% / 12);
    margin-right: calc(100% / 12);
  };
  @include breakpoint(small down){
    margin-left: calc(100% / 24);
    margin-right: calc(100% / 9);
  };
}

.tydo-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  padding: rem-calc(32 * 2) 0;
  @include breakpoint(small down){
    margin-bottom: 0;
  };
}

.tydo-columns{
  display: flex;
  @include breakpoint(small down){
    flex-direction: column;
  };
}

.tydo-column{
  flex-basis: 50%;
  margin: 0;
  margin-bottom: rem-calc(32);
  &,
  &-row{
    padding: 0 rem-calc(8);
  }
  &-row,
  &__row{
    display: flex;
    align-items: baseline;
    margin-top: rem-calc(-2);
    margin-bottom: rem-calc(14);
  }
  dt{
    min-width: 4.5em;
    margin-right: 0.5em;
  }
  dd{
    margin-left: 0;
    margin-right: 1em;
  }
}

div.tydo-column{
  dt{
    // @extend %ja-font-regular;
    margin-top: rem-calc(0);
    margin-bottom: rem-calc(10);
    line-height: 1;
    font-size: rem-calc(20);
  }
  dd{
    margin-left: 0;
    margin-bottom: rem-calc(-4);
    font-size: rem-calc(17);
  }
}

.small-vartical{
  @include breakpoint(small down){
    flex-direction: column;
    margin-bottom: rem-calc(24);
    dt{
      margin-bottom: rem-calc(8);
    }
  };
}
