$axisRegular:'AxisStd-Regular';
$axisMedium:'AxisStd-Medium';
$axisBold:'AxisStd-Bold';





$en-font-book:'Futura ND Book W01 Regular';
$en-font-medium:'Futura ND W01 Medium';
$en-font-demi:'Futura ND Demibold W01 Regular';
$en-font-bold:'Futura ND W01 Bold';
$en-font: $en-font-demi;

%ja-font{
  font-family: $en-font-medium, $axisMedium, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}
%ja-font-regular { @extend %ja-font; font-family: $en-font-book, $axisRegular, sans-serif; }
%ja-font-medium { @extend %ja-font; font-family: $en-font-medium, $axisMedium, sans-serif; }
%ja-font-bold { @extend %ja-font; font-family: $en-font-demi, $axisBold, sans-serif; }

%en-font{
  font-family: $en-font, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: inherit;
}
%en-font-book { @extend %en-font; font-family: $en-font-book, sans-serif; }
%en-font-demi { @extend %en-font; font-family: $en-font-demi, sans-serif; }
%en-font-bold { @extend %en-font; font-family: $en-font-bold, sans-serif; }

//sizeing
$window-min: 320px;
$window-max: 1280px;
$base-min: 21px; $base-max: 24px;

$font-size: (
	 xsmall: linear-interpolation(($window-min: 10px, $window-max: 12px)),
	  small: linear-interpolation(($window-min: 12px, $window-max: 14px)),
     base: linear-interpolation(($window-min: 14px, $window-max: 16px)),
      mid: linear-interpolation(($window-min: 15px, $window-max: 18px)),
	  large: linear-interpolation(($window-min: 16px, $window-max: 22px)),
	 xlarge: linear-interpolation(($window-min: 22px, $window-max: 28px)),
	xxlarge: linear-interpolation(($window-min: 28px, $window-max: 34px)),
);
$line-unit: (
  0.5 : linear-interpolation(($window-min: $base-min * 0.5 , $window-max: $base-max * 0.5)),
  0.75: linear-interpolation(($window-min: $base-min * 0.75, $window-max: $base-max * 0.75)),
  1   : linear-interpolation(($window-min: $base-min * 1   , $window-max: $base-max * 1)),
  1.25: linear-interpolation(($window-min: $base-min * 1.25, $window-max: $base-max * 1.25)),
  1.5 : linear-interpolation(($window-min: $base-min * 1.5 , $window-max: $base-max * 1.5)),
  1.75: linear-interpolation(($window-min: $base-min * 1.75, $window-max: $base-max * 1.75)),
  2   : linear-interpolation(($window-min: $base-min * 2   , $window-max: $base-max * 2)),
  3   : linear-interpolation(($window-min: $base-min * 3   , $window-max: $base-max * 3)),
);

@function font-size($key) {
  @if map-has-key($font-size, $key)
  { @return map-get($font-size, $key); }
  @warn "Unkown '#{$key}' in $font-size."; @return null;
}
@function line-unit($key) {
  @if map-has-key($line-unit, $key)
  { @return map-get($line-unit, $key); }
  @warn "Unkown '#{$key}' in $line-unit."; @return null;
}

.en{ @extend %en-font; font-feature-settings: "tnum", "pwid", "liga" 0, "clig" 0; }
body{
  @extend %ja-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6{ font-feature-settings: "pwid"; }

b, strong{ @extend %ja-font-bold; }
b, strong{ .en &, &.en{ @extend .en; } }
.en, p.en{ @extend .en; }

i{ font-feature-settings: "ital"; }

a{ text-decoration: none; }
